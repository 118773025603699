import styled from "styled-components"
import Colors from "src/ui/colors"

const Styled = {
  Navigation: styled.div`
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
      font-weight: bold;
      color: ${Colors.darkGray};

      img {
        height: 12px;
        width: auto;
      }
    }
  `,
}

export default Styled
