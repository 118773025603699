import React from "react"

// Components
import { Title } from "components/typography"
import { Container, Section, FadeIn } from "components/common"
import Service from "./service.component"

// Styles
import Styled from "./services-grid.styled"

const ServicesGrid = ({ services }) => {
  const renderServices = () => {
    return services.map(({ service, details }) => (
      <Service key={service} service={service} details={details} />
    ))
  }

  return (
    <Styled.Services>
      <Section>
        <Container>
          <Title level={2} blue>
            Services Provided
          </Title>
          <FadeIn>
            <Styled.Grid>{renderServices()}</Styled.Grid>
          </FadeIn>
        </Container>
      </Section>
    </Styled.Services>
  )
}

export default ServicesGrid
