import React from "react"
import { graphql, Link } from "gatsby"

// Components
import {
  Section,
  CaseStudy,
  Footer,
  Layout,
  SEO,
  Container,
} from "src/components/common"
import { Testimonials } from "src/components/landing-page"
import { ServicesGrid } from "src/components/case-studies-page"

// Assets
import ArrowRight from "images/icons/Arrow-Right-Sm.svg"
import ArrowLeft from "images/icons/Arrow-Left-Sm.svg"

// Styles
import Styled from "./case-study.styled"

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        mockup {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        about
        services {
          service
          details
        }
        testimonial {
          client
          text
          clientLogo {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`

const caseStudyTemplate = ({ pageContext, data }) => {
  const { prev, next } = pageContext
  const {
    about,
    mockup,
    services,
    subtitle,
    title,
    testimonial,
  } = data.markdownRemark.frontmatter

  const imageData = mockup.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={`synctree | ${title} Case Study`} />
      <Section pb={1} pt={50}>
        <Container>
          <Styled.Navigation>
            <Link to={`${prev.fields.slug}`}>
              <img src={ArrowLeft} /> {prev.frontmatter.title}
            </Link>
            <Link to={`${next.fields.slug}`}>
              {next.frontmatter.title} <img src={ArrowRight} />
            </Link>
          </Styled.Navigation>
        </Container>
      </Section>
      <CaseStudy
        about={about}
        imageData={imageData}
        sectionTitle={`${title} Case Study`}
        services={services}
        subtitle={subtitle}
      />
      <ServicesGrid services={services} />
      <Testimonials testimonial={testimonial} />
      <Footer heading="Work with us" />
    </Layout>
  )
}

export default caseStudyTemplate
