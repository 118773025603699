import styled from "styled-components"

const Styled = {
  Services: styled.section`
    text-align: center;
  `,

  Grid: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin-top: 50px;

    @media (max-width: 950px) {
      grid-template-columns: 1fr;
    }
  `,
}

export default Styled
