import styled from "styled-components"
import Colors from "ui/colors"

const Styled = {
  Service: styled.div`
    text-align: left;

    @media (max-width: 950px) {
      text-align: center;
    }

    p {
      margin-top: 15px;
      font-size: 24px;
      color: ${Colors.darkGray};
    }
  `,
}

export default Styled
