import React from "react"

// Components
import { Title } from "src/components/typography"

// Styles
import Styled from "./service.styled"

// Types
interface Props {
  service?: string
  details?: string
}

const Service = ({ service, details }: Props) => {
  return (
    <Styled.Service>
      <Title level={4}>{service}</Title>
      <p>{details}</p>
    </Styled.Service>
  )
}

export default Service
